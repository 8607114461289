<template>
  <div class="about">
    <h2>О нас</h2>
    <p>
      Работаем с 2016 года. 
      <br>
      Наша бригада состоит из опытных монтажников. 
      <br>
      Монтируем любые лифты, любого производителя, в любых количествах.
      <br>
      Приоритетом нашей деятельности выступает качество установки лифтового оборудования и последующей пуско-наладки.
      <br>

      <br>
      <b>Чтобы начать процесс монтажа лифтов, Вам просто нужно позвонить по телефону: +7-908-307-95-86.</b>
    </p>
    <div class="imd">
      <img src="../assets/l1.jpg" class="imzv" alt="разгрузка лифтов">
      <img src="../assets/l2.jpg" class="imzv" alt="ящики с лифтами">
      <img src="../assets/l3.jpg" class="imzv" alt="лифтовое оборудование">
      <img src="../assets/l4.jpg" class="imzv" alt="монтаж лифтов">
    </div>
    <div class="imd2">
      <img src="../assets/l5.jpg" class="imlab" alt="установка лифтов">
      <img src="../assets/l6.jpg" class="imlab" alt="сборка кабины лифта">
      <img src="../assets/l7.jpg" class="imlab" alt="лифтовая шахта">
      <img src="../assets/l8.jpg" class="imlab" alt="двери лифта">
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
.about {
  width: 90%;
  margin: 8vh auto;
}
h2 {
  font-size: 2vw;
  margin-bottom: 3vh;
}
p, li {
  font-size: 1vw;
  text-align: justify;
}
.imd {
  display: flex;
  justify-content: space-between;
  margin-top: 4vh;
}
.imd2 {
  display: flex;
  justify-content: space-between;
  margin-top: 4vh;
}
.imzv, .imlab {
  width: 21vw;
}

@media (max-width: 767px) {

  h2 {
    font-size: 23px;
  }
  p, li {
    font-size: 14px;
  }
  .imd {
    display: block;
  }
  .imd2 {
    display: block;
    margin-top: 0.1vh;
  }
  .imzv, .imlab  {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
