<template>
  <div class="home">
    <div class="dev">
      <div class="mr"></div>
      <div class="lf">
        <h1>
          Монтаж любых лифтов, <br>
          любой грузоподъемности, <br>
          на любую высоту подъема! <br>
        </h1>
      </div>
      <div class="zv">
        <h3 class="zvh">Заявка</h3>
        <h4>на бесплатный расчет <br> монтажа лифтового оборудования</h4>
        <form ref="form" @submit.prevent="sendEmail">
          <input class="form-control" type="text" placeholder="Адрес расположения объекта" aria-label="adres" name="adres">
          <input class="form-control" type="text" placeholder="Количество лифтов" aria-label="name" name="quantity">
          <input required class="form-control" type="number" placeholder="Ваш номер телефона" aria-label="phone" name="phone">
          <div class="form-check2">
            <input checked class="form-check-input" type="checkbox" value="persdan" id="flexCheckDefault11">
            &nbsp;
            <label class="form-check-label2" for="flexCheckDefault11">
              Даю согласие на обработку моих персональных данных
            </label>
          </div>
          <div class="dbtn">
            <button type="submit" value="Send" class="btn btn-primary">Отправить</button>
          </div>

          <div class="vis" 
            v-show="visible"
          >
            <p class="sho">
              Заявка успешно отправлена, скоро мы Вам перезвоним. Спасибо!
            </p> 
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="kat">
      <h2>Каталог профилей</h2>
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col">
          <router-link class="lin" to="/GL58">
            <div class="card h-100">
              <img src="../assets/grain2.jpg" class="card-img-top" alt="Grain-Lider">
              <div class="card-body">
                <h5 class="card-title">Grain-Lider 58</h5>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col">
          <router-link class="lin" to="/GP70">
            <div class="card h-100">
              <img src="../assets/grain.jpg" class="card-img-top" alt="Grain-Prestige">
              <div class="card-body">
                <h5 class="card-title">Grain-Prestige 70</h5>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>        
        <div class="col">
          <router-link class="lin" to="/dob70">
            <div class="card h-100">
              <img src="../assets/dob.jpg" class="card-img-top" alt="Добрыня 70(А)">
              <div class="card-body">
                <h5 class="card-title">Добрыня 70(А)</h5>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col">
          <router-link class="lin" to="/ee60">
            <div class="card h-100">
              <img src="../assets/en.jpg" class="card-img-top" alt="Enwin Eco">
              <div class="card-body">
                <h5 class="card-title">Enwin Eco 60</h5>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col">
          <router-link class="lin" to="/eo70">
            <div class="card h-100">
              <img src="../assets/en2.jpg" class="card-img-top" alt="Enwin Omega">
              <div class="card-body">
                <h5 class="card-title">Enwin Omega 70</h5>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col">
          <router-link class="lin" to="/ve58">
            <div class="card h-100">
              <img src="../assets/ve.jpg" class="card-img-top" alt="Vektor">
              <div class="card-body">
                <h5 class="card-title">Vektor 58</h5>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col">
          <router-link class="lin" to="/ve70">
            <div class="card h-100">
              <img src="../assets/ve2.jpg" class="card-img-top" alt="Vektor окна">
              <div class="card-body">
                <h5 class="card-title">Vektor 70</h5>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col">
          <router-link class="lin" to="/np58">
            <div class="card h-100">
              <img src="../assets/np.jpg" class="card-img-top" alt="NordProf">
              <div class="card-body">
                <h5 class="card-title">NordProf 58</h5>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col">
          <router-link class="lin" to="/np70">
            <div class="card h-100">
              <img src="../assets/np2.jpg" class="card-img-top" alt="NordProf окна">
              <div class="card-body">
                <h5 class="card-title">NordProf 70</h5>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div> -->
    <!-- <div class="mr"></div>
    <h2>Расположение фабрики</h2>
    <div class="mr"></div>
    <div class="map">
      <div style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps/org/proizvodstvennaya_kompaniya_okna_lider/23019213161/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Производственная компания Окна Лидер</a><a href="https://yandex.ru/maps/45/cheboksary/category/plastic_products/184106946/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Пластмассовые изделия в Чебоксарах</a><a href="https://yandex.ru/maps/45/cheboksary/category/windows/184107727/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:28px;">Окна в Чебоксарах</a><iframe src="https://yandex.ru/map-widget/v1/?ll=47.307241%2C56.123298&mode=search&oid=23019213161&ol=biz&z=16.37" width="1215" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
    </div> -->
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: 'HomeView',
  data: () => ({     
    visible: false,   
  }),
  methods: {
    sendEmail(event) {
      emailjs.sendForm('service_fmeixzn', 'template_6up5j1k', this.$refs.form, 'ZYblUs5UayMJB-YTn')
        .then((result) => {
          console.log('SUCCESS!', result.text);
        }, (error) => {
          console.log('FAILED...', error.text);
        });
        event.target.reset();
        this.visible = true;
        setTimeout(() => this.visible = false, 10000);
    }
  }
}
</script>

<style scoped>
.home {
  background-image: url(../assets/fon1.jpg);
  background-repeat: repeat-x;
  background-position: center center;
}
.dev {
  height: 100vh;
  width: 60vw;
  background-image: url(../assets/gl.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.zv {
  background: #fff;
  height: 86vh;
  width: 36vw;
  position: absolute;
  top: 36vh;
  left: 58.3%;
  box-shadow: 5px 5px 8px 8px rgba(0, 0, 0, 0.3);
}
.zvh {
  margin-top: 9vh;
  color: #003856;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}
.form-check2 {
  margin-left: 4vw;
}
.form-check-label2 {
  font-size: 1vw;
}
.dbtn {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
}
.btn {
  font-size: 1.5vw;
}
.sho {
  font-size: 1.5vw;
  color: green;
  font-weight: bold;
  margin-top: 3vh;
  text-align: center;
}
.kat {
  width: 90%;
  margin: auto;
  margin-top: 7vh;
}
h1 {
  font-size: 2.5vw;
  text-align: center;
  color: #fff;
  width: 85%;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 2px white, 0 0 1em black, 0 0 0.2em #003856;
}
h4 {
  text-align: center;
  color: #003856;
  margin-bottom: 4vh;
  font-size: 1.8vw;
}
.form-control {
  width: 30vw;
  margin: 2vh auto;
  font-size: 1.2vw;
}
.row {
  margin-top: 4vh;
}
.card-img-top {
  width: 20vw;
  margin: auto;
}
.card-title {
  text-align: center;
  font-size: 1.5vw;
}
.lin {
  text-decoration: none;
}
.card:hover {
  transform: scale(1.01);
  box-shadow: 1px 1px 5px 5px rgba(185, 185, 185, 0.7);
  transition: all 0.1s ease;
}
.mr {
  height: 7vh;
}
.map {
  width: 90%;
  margin: auto;
}
iframe {
  width: 100%;
  height: 80vh;
}

@media (max-width: 767px) {

  .home {
    background-repeat: repeat-y;
  }
  .dev {
    height: 120vh;
    width: 100%;
    background-position: center top;
  }
  .kat {
    margin-top: 97vh;
  }
  .zv {
    height: 80vh;
    width: 90vw;
    top: 55vh;
    left: 5%;
    box-shadow: 5px 5px 8px 8px rgba(0, 0, 0, 0.3);
  }
  .zvh {
    font-size: 30px;
  }
  h4 {
    margin-bottom: 4vh;
    font-size: 26px;
  }
  .form-control {
    width: 80vw;
    font-size: 20px;
  }
  .form-check-input {
    margin-left: 1%;
    width: 5vw;
    height: 5vw;
  }
  .form-check-label2 {
    font-size: 11px;
  }
  .btn {
    font-size: 20px;
  }
  .sho {
    font-size: 16px;
    color: green;
    font-weight: bold;
    margin-top: 3vh;
    text-align: center;
  }
  h1 {
    font-size: 24px;
    width: 100%;
  }
  .card-img-top {
    width: 70vw;
  }
  .card-title {
    font-size: 20px;
  }
}

</style>
