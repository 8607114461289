<template>
  <div class="job">
    <h2>Наши работы</h2>
    <div class="row row-cols-1 row-cols-md-6 g-6">
        <div class="col">
            <a class="lin" href="https://resident18.com/vremena-goda-izhevsk">
                <div class="card h-100">
                    <img src="../assets/vg.jpg" class="card-img-top" alt="монтаж настилов">
                    <div class="card-body">
                    <h5 class="card-title">ЖК "Времена года"</h5>
                    <p class="card-text">
                        Изготовление и монтаж настилов по адресу: г.Ижевск, ул.Баранова
                    </p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a class="lin" href="https://www.domkor-dom.com/prodazha-kvartir-Novostroiki/kvartira-Naberezhnye-Chelny/zhiloj-kompleks-novye-prostory-69-41-3/#p1">
                <div class="card h-100">
                    <img src="../assets/nc2.jpg" class="card-img-top" alt="монтаж лифтов">
                    <div class="card-body">
                    <h5 class="card-title">ЖК "Новые просторы"</h5>
                    <p class="card-text">
                        Монтаж лифтов по адресу: г.Набережные Челны, 69-й комплекс
                    </p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a class="lin" href="https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82/9018">
                <div class="card h-100">
                    <img src="../assets/nu.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                    <h5 class="card-title"> 9-этажный многоквартирный дом</h5>
                    <p class="card-text">
                        Монтаж лифтов по адресу: г. Новый Уренгой, мкр Созидателей
                    </p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a class="lin" href="">
                <div class="card h-100">
                    <img src="../assets/ck1.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                    <h5 class="card-title">ЖК </h5>
                    <p class="card-text">
                        
                    </p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a class="lin" href="">
                <div class="card h-100">
                    <img src="../assets/ck1.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                    <h5 class="card-title">ЖК </h5>
                    <p class="card-text">
                        
                    </p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a class="lin" href="">
                <div class="card h-100">
                    <img src="../assets/ck1.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                    <h5 class="card-title">ЖК</h5>
                    <p class="card-text">
                        
                    </p>
                    </div>
                </div>
            </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.job {
  width: 90%;
  margin: 8vh auto;
}
h2 {
  font-size: 2vw;
  margin-bottom: 3vh;
}
p {
  margin-top: 2vw;
  font-size: 1.1vw;
  text-align: justify;
}
.row {
    margin-top: 4vh;
}
.col {
    margin-bottom: 15px;
}
.card-title, .lin {
    text-decoration: none;
    font-size: 1.3vw;
}
.card-text {
    font-size: 1vw;
    text-align: left;
}
.card:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 5px 5px rgba(185, 185, 185, 0.7);
    transition: all 0.1s ease;
}

@media (max-width: 767px) {

    h2 {
        font-size: 23px;
    }
    .job {
        height: auto;
    }
    p {
        font-size: 14px;
    }   
    .card-title {
        font-size: 19px;
    }
    .card-text {
        font-size: 16px;
    }

}
</style>