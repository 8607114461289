<template>
  <div class="d1">
    <h2 class="imt">Квалифицированный монтаж <br> лифтового оборудования</h2>
    <router-link class="opa" to="/menu">
      <svg xmlns="http://www.w3.org/2000/svg" fill="gold" class="bi bi-list" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
      </svg>
    </router-link>
    <div class="d2">
      <router-link to="/"> 
        <img class="iml" alt="бригада монтажников" src="./assets/logo.jpg">
      </router-link>
    </div>
    <div class="d3">
      <nav>
        <router-link to="/">Главная</router-link> 
        <router-link to="/about">О нас</router-link>
        <router-link to="/jobs">Наши работы</router-link>
        <router-link to="/vacancies">Вакансии</router-link>
        <router-link to="/contacts">Контакты</router-link>
      </nav>
    </div>
    <div class="d4">  
      <router-link to="/contacts" class="pch">
        <img class="imm" alt="" src="./assets/mar2.png">
        &nbsp;
        <p>г. Чебоксары, Чебоксарский проспект, 15</p>
      </router-link>
    </div>
    <div class="d5">
      <a class="tel" href="tel:+79083079586">+7-908-307-95-86</a>
    </div>
  </div>
  <router-view/>
  <div class="f1">
    <div class="f2">
      <div class="mr"></div>
      <!-- <div class="box">
        <router-link to="/certificates" class="fli">Сертификат соответствия</router-link> <br>
        <router-link to="/declarations" class="fli">Декларация соответствия</router-link> <br>
        <router-link to="/requisites" class="fli">Карта партнера</router-link> <br>
        <router-link to="/articles" class="fli">Полезная информация</router-link>
      </div> -->
      <div class="box">
        <a class="fli" href="mailto:mopost@mail.ru">mopost@mail.ru</a> <br>
      </div>
      <div class="box">
        <router-link to="/contacts" class="fli">Контакты</router-link> <br>
      </div>
    </div>
  </div>
</template>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.opa,
.bi {
  display: none;
}
.d1 {
  display: flex;
  align-items: center;  
  height: 29vh;
  background-image: url(./assets/head2.jpg);
  background-repeat: repeat-x;
}
.d2 {
  position: absolute;
  top: 4vh;
  left: 5vw;
}
.d3 {
  width: 90%;
  background: #394952;
  height: 8vh;
  display: flex;
  align-items: center;  
  justify-content: space-around;
  position: sticky;
  top: 23vh;
  margin: auto;
}
.d4 {
  position: absolute;
  left: 15vw;
  top: 12vh;
}
.d5 {
  position: absolute;
  left: 49vw;
  top: 3.3vh;
}
nav a {
  font-weight: bold;
  color: #fff;
  margin-right: 5vw;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.1vw;
  transition: all 0.4s ease;
  position: relative;
  width: 90%;
}
a:hover {
  color: gold;
  transition: all 0.4s ease;
}
nav a.router-link-exact-active {
  color: gold;
}
.imt {
  position: absolute;
  top: 4vh;
  left: 15vw;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.5vw;
}
.iml {
  width: 9vw;
}
.imm {
  height: 3vh;  
  margin-top: -0.6vh;
  /* color: #fff; */
}
.pch {
  text-decoration: none;
  font-size: 1vw;
  color: #fff;
  font-weight: bold;
  display: flex;
  margin-top: 2.1vh;
}
.tel {
  text-decoration: none;
  color: #fff;
  font-size: 2vw;
  font-weight: bold;
}
p {
  font-size: 0.85vw;
}
.f1 {
  height: 30vh;
  background-image: url(./assets/head2.jpg);
  background-repeat: repeat-x;
  background-position: center center;
}
.f2 {
  display: grid;
  grid-template-rows: (6, 1fr);
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 17%;
}
.fli {
  color: #fff;
  font-size: 1.5vw;
  text-decoration: none;
  transition: all 0.4s ease;
  white-space: nowrap
}
.fli:hover {
  color: gold;
  transition: all 0.4s ease;
}
.box {
  margin-top: 12vh;
}

@media (max-width: 767px) {

  .opa,
  .bi {
    display: inline;
    width: 50px;
    position: fixed;
    margin-top: -4.5%;
    left: 82%;
    z-index: 10;
  }
  .d1 {
    height: 22vh;
  }
  .d3, .d4 {
    display: none;
  }
  .d2 {
    top: 3vh;
  }
  .iml {
    width: 30vw;
  }
  .imt {
    left: 40vw;
    font-size: 12px;
    width: 30vw;
    top: 2.5vh;
  }
  .d5 {
    top: 14vh;
    left: 40vw;
  }
  .tel {
    font-size: 20px;
  }
  .f1 {
    height: 30vh;
  }
  .f2 {
    display: block;
    margin-left: 20px;
  }
  .mr {
    height: 10vh;
  }
  .fli {
    font-size: 21px;
  }
  .box {
    margin-top: 0;
  }
}
</style>

