<template>
    <div class="mm">
        <div class="mr"></div>
        <div class="menu">
            <nav>
                <router-link to="/">Главная</router-link>
                <router-link to="/about">О нас</router-link>
                <router-link to="/jobs">Наши работы</router-link>
                <router-link to="/vacancies">Вакансии</router-link>
                <router-link to="/contacts">Контакты</router-link>
            </nav>
        </div>
        <div class="bt">
            <a 
                href="tel:+79083079586"
            >
                <button class="btp">Позвонить</button>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.mm {
    height: 100vh;
    background-image: url(../assets/fon1.jpg);
    background-repeat: repeat-x;
    background-position: center center;
}
.mr {
    height: 40px;
}
.menu {
    display: flex;
    justify-content: center;    
}
nav a {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    color: #fff;
    margin-left: 4vw;
    text-decoration: none;
    font-size: 20px;
    transition: all 0.4s ease;
}
nav a:hover {
    color: gold;
    transition: all 0.4s ease;
}
.bt {
    display: grid;
    justify-content: center;
}
.btp {
    margin-top: 10vh;
    width: 70vw;
    height: 6vh;
    background-color: gold;
    border: 2px solid gold;
    color: black;
    border-radius: 50px;
    font-size: 19px;
}
</style>