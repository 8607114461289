<template>
  <div class="k1">
    <h2>Контакты</h2>
    <h3>Бригада опытных монтажников</h3>
    <p>
      <b>Адрес:</b> 428000, Чувашская Республика - Чувашия, г. Чебоксары, Чебоксарский проспект, 15
    </p>
    <p>
      <b>Телефон:</b> <a class="tel" href="tel:+79083079586">+7-908-307-95-86</a>
    </p>
    <p>
      <b>Email:</b> <a href="mailto:mopost@mail.ru">mopost@mail.ru</a>
    </p>
    <div class="map">
      <div style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps/45/cheboksary/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Чебоксары</a><a href="https://yandex.ru/maps/45/cheboksary/house/cheboksarskiy_prospekt_15/YE4YdQBmTkMFQFtsfX1zdHtqZQ==/?ll=47.376762%2C56.125791&utm_medium=mapframe&utm_source=maps&z=16.48" style="color:#eee;font-size:12px;position:absolute;top:14px;">Чебоксарский проспект, 15 — Яндекс Карты</a><iframe src="https://yandex.ru/map-widget/v1/?ll=47.376762%2C56.125791&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxODQwMDI2MzAxEnjQoNC-0YHRgdC40Y8sINCn0YPQstCw0YjRgdC60LDRjyDQoNC10YHQv9GD0LHQu9C40LrQsCwg0KfQtdCx0L7QutGB0LDRgNGLLCDQp9C10LHQvtC60YHQsNGA0YHQutC40Lkg0L_RgNC-0YHQv9C10LrRgiwgMTUiCg3PgT1CFdCAYEI%2C&utm_source=main_stripe_big&z=16.48" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.k1 {
  width: 90%;
  margin: 8vh auto;
}
h2 {
  font-size: 2vw;
  margin-bottom: 3vh;
}
h3 {
  margin-top: 2vw;
  font-size: 2vw;
}
p {
  font-size: 1.1vw;
  text-align: justify;
}
a {
  font-size: 1.1vw;
  color: black;
}
.map {
  margin-top: 3vw;
}
iframe {
  height: 90vh;
  width: 100%;
}

@media (max-width: 767px) {

  h2 {
    font-size: 23px;
  }
  h3 {
    font-size: 24px;
    margin-top: 30px;
  }
  p, a {
    font-size: 14px;
  }

}
</style>